import makeValidationSchema from './TradePartyMakeValidationSchema'
import { components, constants, useTranslation } from 'cng-web-lib'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SbciInvoiceKeys from 'src/constants/locale/key/SbciInvoice'
import { Grid, Typography } from '@material-ui/core'
import {NaPartyMasterAutoCompleteField, NaStateCodeAutoCompleteField} from 'src/components/na'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'

const {
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
    },
  },
  CngGridItem,
} = components

const { filter: { EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyIdn: "",
  partyName: "",
  partyType: "",
  upsClientId: "",
  mid: "",
  irs: "",
  division: "",
  locPortCode: "",
  poBoxNo: "",
  address: "",
  city: "",
  stateCode: "",
  countryCode: "",
  postalCode: "",
  telphNum: "",
  contactFax: "",
  contactEmail: "",
  taxIdNumber: "",
  contactName: "",
  saveFlag: false,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap
}) {
  const { translate } = useTranslation(Namespace.SBCI_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const { setValue, trigger, watch, getValues } = useFormContext()
  const saveFlag = watch('saveFlag')
  const partyType = watch('partyType')
  const countryCode = watch('countryCode')

  function makeTranslatedTextsObject() {
    let partyIdn = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PARTY_IDN
    )
    let partyName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PARTY_NAME
    )
    let partyType = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PARTY_TYPE
    )
    let upsClientId = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.UPS_CLIENT_ID
    )
    let mid = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.MID
    )
    let irs = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.IRS
    )
    let division = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.DIVISION
    )
    let locPortCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.LOC_PORT_CODE
    )
    let poBoxNo = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PO_BOX_NO
    )
    let address = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.ADDRESS
    )
    let city = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.CITY
    )
    let stateCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.STATE_CODE
    )
    let countryCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.COUNTRY_CODE
    )
    let postalCode = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.POSTAL_CODE
    )
    let telphNum = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.TELPH_NUM
    )
    let contactFax = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.CONTACT_FAX
    )
    let contactEmail = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.CONTACT_EMAIL
    )
    let taxIdNumber = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.TAX_ID_NUMBER
    )
    let contactName = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.CONTACT_NAME
    )

    let saveFlag = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.SAVE_FLAG
    )

    let partyDropDown = translate(
      Namespace.SBCI_INVOICE,
      SbciInvoiceKeys.TradeParty.PARTY_DROP_DOWN
    )

    return {
      partyIdn,
      partyName,
      partyType,
      upsClientId,
      mid,
      irs,
      division,
      locPortCode,
      poBoxNo,
      address,
      city,
      stateCode,
      countryCode,
      postalCode,
      telphNum,
      contactFax,
      contactEmail,
      taxIdNumber,
      contactName,
      saveFlag,
      partyDropDown
    }
  }

  function handleApplyTemplate(template) {
    if(!template) return

    const { data } = template

    const {
      partytype,
      partyname1,
      partyname2,
      address1,
      cityname,
      postalcode,
      statecode,
      countrycode,
      contactperson,
      telephonenumber,
      division,
      locPortCode,
      poBoxNumber,
      faxNo,
      email,
      partyIdentifier
    } = data

    const mid = partyIdentifier.filter(item => item.partyIdentifierCode === 'MID')
    const irs = partyIdentifier.filter(item => item.partyIdentifierCode === 'IRS')
    const upsc = partyIdentifier.filter(item => item.partyIdentifierCode === 'UPSC')
    setValue('partyType', _.isEmpty(partytype) ? '' : partytype[0], { shouldDirty: true })
    setValue('partyName', partyname1 || '', { shouldDirty: true })
    setValue('mid', (mid != undefined && mid.length > 0 && mid[0].partyIdentifierNumber) || '', { shouldDirty: true })
    setValue('irs', (irs != undefined && irs.length > 0 && irs[0].partyIdentifierNumber) || '', { shouldDirty: true })
    setValue('upsClientId', (upsc != undefined && upsc.length > 0 && upsc[0].partyIdentifierNumber) || '', { shouldDirty: true })
    setValue('address', address1 || '', { shouldDirty: true })
    setValue('city', cityname || '', { shouldDirty: true })
    setValue('postalCode', postalcode || '', { shouldDirty: true })
    setValue('countryCode', countrycode || '', { shouldDirty: true })
    setValue('stateCode', statecode || '', { shouldDirty: true })
    setValue('contactName', contactperson || '', { shouldDirty: true })
    setValue('telphNum', telephonenumber || '', { shouldDirty: true })
    setValue('division', division || '', { shouldDirty: true })
    setValue('locPortCode', locPortCode || '', { shouldDirty: true })
    setValue('poBoxNo', poBoxNumber || '', { shouldDirty: true })
    setValue('contactFax', faxNo || '', { shouldDirty: true })

    if(email!=null && email!=undefined && email!=""){
      const emailArray = email.split(",");
      setValue('contactEmail', emailArray[0])
    }else{
      setValue('contactEmail','')
    }

    trigger()
  }

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.saveFlag}>
            <CngCheckboxField
              label={
                <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                  {translatedTextsObject.saveFlag}
                </Typography>
              }
              disabled={disabled}
              onChange={(event) => setValue('saveFlag', event.target.checked)}
              size='small'
              value={saveFlag}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.partyIdn}>
            <CngTextField
              name='partyIdn'
              label={translatedTextsObject.partyIdn}
              disabled={!saveFlag}
              onChange={(event) => setValue('partyIdn', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.partyDropDown}>
            <NaPartyMasterAutoCompleteField
              name='partyDropDown'
              label={translatedTextsObject.partyDropDown}
              disabled={disabled}
              onChange={(_, template) => handleApplyTemplate(template)}
              size='small'
              lookupProps={{
                filters: [{
                  field: 'partyId',
                  operator: EQUAL,
                  value: FileForUserGetPartyId()
                }]
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.partyType}>
            <CngCodeMasterAutocompleteField
              codeType='SBCI_PARTY_TYPE'
              name='partyType'
              key={partyType}
              label={translatedTextsObject.partyType}
              disabled={disabled}
              required
              size='small'
              onChange={(_, option) =>
                setValue('partyType', option ? option.value : '', {shouldValidate:true})
              }
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.partyName}>
            <CngTextField
              name="partyName"
              label={translatedTextsObject.partyName}
              disabled={disabled}
              onChange={(event) => setValue('partyName', event.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.mid}>
            <CngTextField
              name="mid"
              label={translatedTextsObject.mid+" **"}
              disabled={disabled}
              onChange={(event) => {
                setValue('mid', event.target.value.toUpperCase(), {shouldValidate:true}) 
                trigger(["irs"])
            }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.upsClientId}>
            <CngTextField
              name="upsClientId"
              label={translatedTextsObject.upsClientId}
              disabled={disabled}
              onChange={(event) => setValue('upsClientId', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.irs}>
            <CngTextField
              name="irs"
              label={translatedTextsObject.irs+" **"}
              disabled={disabled}
              onChange={(event) => {
                setValue('irs', event.target.value.toUpperCase(), {shouldValidate:true})
                trigger(["mid"])
              }} 
              size='small'
              helperText='Format: XX-XXXXXXXXX'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.division}>
            <CngTextField
              name="division"
              label={translatedTextsObject.division}
              disabled={disabled}
              onChange={(event) => setValue('division', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.locPortCode}>
            <CngTextField
              name="locPortCode"
              label={translatedTextsObject.locPortCode}
              disabled={disabled}
              onChange={(event) => setValue('locPortCode', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.poBoxNo}>
            <CngTextField
              name="poBoxNo"
              label={translatedTextsObject.poBoxNo}
              disabled={disabled}
              onChange={(event) => setValue('poBoxNo', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} shouldHide={shouldHideMap.address}>
            <CngTextField
              name="address"
              label={translatedTextsObject.address}
              disabled={disabled}
              onChange={(event) => setValue('address', event.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.city}>
            <CngTextField
              name="city"
              label={translatedTextsObject.city}
              disabled={disabled}
              onChange={(event) => setValue('city', event.target.value.toUpperCase(), {shouldValidate:true})}
              required
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.countryCode}>
            <CngCountryAutocompleteField
              name="countryCode"
              key={countryCode}
              label={translatedTextsObject.countryCode}
              disabled={disabled}
              required
              size='small'
              lookupProps={{
                label: (record) => `${record.code};${record.descriptionEn}`
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.stateCode}>
            <NaStateCodeAutoCompleteField
              name="stateCode"
              label={translatedTextsObject.stateCode}
              disabled={disabled}
              countryCode={countryCode}
              key={countryCode}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.postalCode}>
            <CngTextField
              name="postalCode"
              label={translatedTextsObject.postalCode}
              disabled={disabled}
              onChange={(event) => setValue('postalCode', event.target.value.toUpperCase(), {shouldValidate:true})}
              required={countryCode == 'CA' || countryCode == 'US'}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.telphNum}>
            <CngTextField
              name="telphNum"
              label={translatedTextsObject.telphNum}
              disabled={disabled}
              onChange={(event) => setValue('telphNum', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.contactName}>
            <CngTextField
              name="contactName"
              label={translatedTextsObject.contactName}
              disabled={disabled}
              onChange={(event) => setValue('contactName', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.contactFax}>
            <CngTextField
              name="contactFax"
              label={translatedTextsObject.contactFax}
              disabled={disabled}
              onChange={(event) => setValue('contactFax', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.contactEmail}>
            <CngTextField
              name="contactEmail"
              label={translatedTextsObject.contactEmail}
              disabled={disabled}
              onChange={(event) => setValue('contactEmail', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap.taxIdNumber}>
            <CngTextField
              name="taxIdNumber"
              label={translatedTextsObject.taxIdNumber}
              disabled={disabled}
              onChange={(event) => setValue('taxIdNumber', event.target.value.toUpperCase(), {shouldValidate:true})}
              size='small'
            />
          </CngGridItem>
        </Grid>
      </Grid>
    </Grid>
	)
}

const TradePartyFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default TradePartyFormProperties
